<template>
    <n-config-provider
        :theme="theme.naiveTheme"
        :theme-overrides="theme.naiveThemeOverrides"
        :locale="lang"
        :date-locale="dateZhCN"
        class="h-full"
    >
        <naive-provider>
            <router-view />
        </naive-provider>
    </n-config-provider>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue"
import { dateZhCN, zhCN, enUS } from "naive-ui"
import { subscribeStore, useThemeStore } from "@/store"
import { useGlobalEvents } from "@/composables"
const url = new URL(window.location.href)
const urlLang = url.searchParams.get("lang") as string
const lang = ref<any>("")
const theme = useThemeStore()

subscribeStore()
useGlobalEvents()

onBeforeMount(() => {
    if (urlLang === "zh_CN") {
        lang.value = zhCN
        return
    }
    lang.value = enUS
})
</script>

<style scoped></style>
